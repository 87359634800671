import {graphql} from 'gatsby';
import React, {useState, useEffect} from 'react';
import AdvantagesBlock from '../components/CaseTemplates/AdvantagesBlock';
import Carousel360 from '../components/CaseTemplates/Carousel360';
import CaseClassification from '../components/CaseTemplates/CaseClassification';
import CaseFooter from '../components/CaseTemplates/CaseFooter';

import MainHighlight from '../components/CaseTemplates/MainHighlight';
import SimpleTextBlock from '../components/CaseTemplates/SimpleTextBlock';
import Layout from '../components/layout';

import SEO from '../components/SEO/SEO';
import '../styles/templates/case.scss';
import '../styles/utils/general.scss';
import website from '../utils/site-config';

import LogoMenu from '../components/CaseTemplates/LogoMenu';
import {makeScrollWaypoint} from '../utils/helpers';
import {makeScrollWaypointBottom} from '../utils/helpers';
import Banner3D from '../components/CaseTemplates/Banner3D';

import AuthUserContext from '../components/Session/AuthUserContext';
import WithAuthentication from '../components/Session/WithAuthentication';
import PanoCarousel360 from '../components/CaseTemplates/PanoCarousel360';

import {isTouchDevice} from '../utils/helpers';
import ReactGA from 'react-ga';

const TemplateC = ({
  pageContext: {
    locale,
    folderName,
    path,
    casesArrayForPage,
    previousPathForPage,
  },
  data,
  location,
}) => {
  return (
    <AuthUserContext.Consumer>
      {authUser =>
        !authUser && data.file.childMarkdownRemark.frontmatter.public === false
          ? <PrivateCaseNoLogin data={data} locale={locale} />
          : <CaseC
              locale={locale}
              casesArrayForPage={casesArrayForPage}
              previousPathForPage={previousPathForPage}
              data={data}
              location={location}
            />}
    </AuthUserContext.Consumer>
  );
};

export default WithAuthentication (TemplateC);

class PrivateCaseNoLogin extends React.Component {
  componentDidMount () {
    var event = new CustomEvent ('requestLogin');
    // Dispatch the event
    window.dispatchEvent (event);
  }

  render () {
    return (
      <Layout
        path={this.props.data.file.childMarkdownRemark.frontmatter.path}
        locale={this.props.locale}
        headerLogoColor={'#0033a0'}
      />
    );
  }
}

const CaseC = ({
  locale,
  casesArrayForPage,
  previousPathForPage,
  data,
  location,
}) => {
  const [scrollInteraction, setScrollInteraction] = useState (false);
  const [headerLogoColor, setHeaderLogoColor] = useState ('white');
  const [sideMenuLogoIsHidden, setSideMenuLogoIsHidden] = useState (true);
  const [sideMenuLogoColor, setSideMenuLogoColor] = useState ('white');
  const [hasReadTillEnd, setHasReadTillEnd] = useState (false);

  var logoSideMenuIsHidden = sideMenuLogoIsHidden === true ? 'is-hidden' : '';

  const fileFrontmatter = data.file.childMarkdownRemark.frontmatter;
  var previousPath = previousPathForPage;
  var currentCasesArray = casesArrayForPage;

  if (location.state) {
    previousPath = location.state.previousPath;
    previousPathForPage = location.state.previousPath;
  }

  if (location.state) {
    currentCasesArray = location.state.currentCasesArray;
  }

  const handleScroll = () => {
    var logoColor = 'white';
    var sideMenuIsHidden = false;
    var sideMenuLogoColor = 'white';

    //TODO fix this spaghetti

    /**
     * Header reaches top of el
     * difference between el + space that it takes and top of viewport reaches 0
     **/

    //waypoints for header logo - control areas to check if el reaches top of viewport

    var caseClassificationElementWaypoint = makeScrollWaypoint (
      '.case--case-classification-wrapper'
    );

    var carousel360Waypoint = makeScrollWaypoint ('.carousel-360-container');

    var caseContentSolutionWaypoint = makeScrollWaypoint (
      '.case-content-solution'
    );

    var multimediaGalleryWaypoint = makeScrollWaypoint ('.multimedia-gallery');

    var caseContentWorkProcessWaypoint = makeScrollWaypoint (
      '.case-content-work-process'
    );

    var advantagesWaypoint = makeScrollWaypoint ('.advantages-container');

    // setting logo color and hamburguer menu color with scroll

    if (caseClassificationElementWaypoint <= 0) {
      logoColor = '#0033a0';

      if (carousel360Waypoint <= 0) {
        logoColor = 'white';

        if (caseContentSolutionWaypoint <= 0) {
          logoColor = '#0033a0';

          if (multimediaGalleryWaypoint <= 0) {
            logoColor = 'white';

            if (caseContentWorkProcessWaypoint <= 0) {
              logoColor = '#0033a0';

              if (advantagesWaypoint <= 0) {
                logoColor = 'white';
              }
            }
          }
        }
      }
    }

    /**
     * Side menu in bottom reaches top of el
     *
     * difference between bottom - space element takes reaches 0
     *
     **/

    //waypoints for side menu logo

    var panoBannerWaypointBottom = makeScrollWaypoint ('.case--banner-wrapper');

    var carousel360WaypointBottom = makeScrollWaypointBottom (
      '.carousel-360-container'
    );

    var caseContentSolutionWaypointBottom = makeScrollWaypointBottom (
      '.case-content-solution'
    );

    var multimediaGalleryWaypointBottom = makeScrollWaypointBottom (
      '.multimedia-gallery'
    );

    var caseContentWorkProcessWaypointBottom = makeScrollWaypointBottom (
      '.case-content-work-process'
    );

    var advantagesWaypointBottom = makeScrollWaypointBottom (
      '.advantages-container'
    );

    if (panoBannerWaypointBottom < -50) {
      sideMenuLogoColor = '#0033a0';
      sideMenuIsHidden = false;

      if (carousel360WaypointBottom <= 0) {
        sideMenuLogoColor = 'white';
      }
      if (caseContentSolutionWaypointBottom <= 0) {
        sideMenuLogoColor = '#0033a0';
        if (multimediaGalleryWaypointBottom <= 0) {
          sideMenuLogoColor = 'white';
          if (caseContentWorkProcessWaypointBottom <= 0) {
            sideMenuLogoColor = '#0033a0';
          }
          if (advantagesWaypointBottom <= 0) {
            sideMenuLogoColor = 'white';

            setHasReadTillEnd (true);
          }
        }
      }
    } else {
      sideMenuIsHidden = true;
    }

    setSideMenuLogoIsHidden (sideMenuIsHidden);
    setHeaderLogoColor (logoColor);
    setSideMenuLogoColor (sideMenuLogoColor);
  };

  useEffect (() => {
    window.addEventListener ('scroll', handleScroll);

    return () => {
      window.removeEventListener ('scroll', handleScroll);
    };
  });

  useEffect (
    () => {
      setHasReadTillEnd (false);
    },
    [fileFrontmatter.path]
  );

  useEffect (
    () => {
      if (hasReadTillEnd) {
        ReactGA.event ({
          category: 'Showcase',
          action: 'readFullContent',
        });
      }
    },
    [hasReadTillEnd]
  );

  return (
    <Layout
      path={fileFrontmatter.path}
      locale={locale}
      headerLogoColor={headerLogoColor}
    >
      <SEO
        title={`${fileFrontmatter.title} | ${website._title}`}
        pathname={fileFrontmatter.path}
        desc={fileFrontmatter.description}
        banner={fileFrontmatter.image.publicURL}
        showCase
      />
      <div className="case-container">
        <div
          className="case--banner-wrapper"
          onWheel={() => setScrollInteraction (true)}
          onTouchStart={
            typeof window !== `undefined` && isTouchDevice ()
              ? () => setScrollInteraction (true)
              : undefined
          }
        >
          <Banner3D
            banner3D={fileFrontmatter.banner3D}
            title={fileFrontmatter.title}
            productTypeShort={fileFrontmatter.productTypeShort}
            segmentTag={fileFrontmatter.segmentTag}
            areaTag={fileFrontmatter.areaTag}
            solutionTag={fileFrontmatter.solutionTag}
            productType={fileFrontmatter.productType}
            scrollInteraction={scrollInteraction}
            scrollInteractionSet={setScrollInteraction}
          />
        </div>
        <div className="case--content-wrapper">
          <div className={'logo-menu ' + logoSideMenuIsHidden}>
            <LogoMenu
              logoMenuColor={sideMenuLogoColor}
              productTypeShort={fileFrontmatter.productTypeShort}
              locale={locale}
            />
          </div>
          <div className="case--case-classification-wrapper">
            <CaseClassification
              title={fileFrontmatter.title}
              segmentTag={fileFrontmatter.segmentTag}
              areaTag={fileFrontmatter.areaTag}
              solutionTag={fileFrontmatter.solutionTag}
              tags={fileFrontmatter.tags}
              productTypeShort={fileFrontmatter.productTypeShort}
              productType={fileFrontmatter.productType}
              client={fileFrontmatter.client}
              brochureMessage={fileFrontmatter.brochureMessage}
              brochureURL={fileFrontmatter.brochure.publicURL}
              readingTime={fileFrontmatter.readingTime}
              locale={locale}
            />
          </div>
          <div className="text-highlight-wrapper--case">
            <MainHighlight
              paddingTop={'130px'}
              image={fileFrontmatter.highlightImage}
              textContent={fileFrontmatter.caseContentMainHighlight}
            />
          </div>
          <div className="case-content-intro">
            <SimpleTextBlock
              paddingTop={'90px'}
              text={fileFrontmatter.caseContentIntro}
              textColor={'#333333'}
            />
          </div>
          <div className="case-content-opportunity">
            <SimpleTextBlock
              paddingTop={'130px'}
              text={fileFrontmatter.caseContentOpportunity}
              textColor={'#333333'}
              header={fileFrontmatter.caseContentOpportunityHeader}
            />
          </div>
          <div className="carousel-360-container">
            {fileFrontmatter.panoCarousel360
              ? <PanoCarousel360
                  panos={fileFrontmatter.panoCarousel360}
                  messageFor360Gallery={fileFrontmatter.messageFor360Gallery}
                  externalLink360Gallery={
                    fileFrontmatter.externalLink360Gallery
                  }
                  panoCarouselFolder={fileFrontmatter.panoCarouselFolder}
                />
              : <Carousel360
                  paddingTop={'130px'}
                  images={fileFrontmatter.gallery360}
                  messageFor360Gallery={fileFrontmatter.messageFor360Gallery}
                  externalLink360Gallery={
                    fileFrontmatter.externalLink360Gallery
                  }
                />}
          </div>

          <div className="case-content-solution">
            <SimpleTextBlock
              paddingTop={'130px'}
              text={fileFrontmatter.caseContentSolution}
              textColor={'#333333'}
              header={fileFrontmatter.caseContentSolutionHeader}
            />
          </div>
          <div className="multimedia-gallery" />
          <div className="case-content-work-process" style={{paddingTop: 0}}>
            <SimpleTextBlock
              paddingTop={'130px'}
              text={fileFrontmatter.caseContentWorkProcess}
              textColor={'#333333'}
              header={fileFrontmatter.caseContentWorkProcessHeader}
            />
          </div>

          <div className="advantages-block">
            <AdvantagesBlock
              paddingTop={'130px'}
              header={fileFrontmatter.caseContentAdvantagesHeader}
              advantagesContent={fileFrontmatter.caseContentAdvantages}
              bgPicture={fileFrontmatter.caseContentAdvantagesBackGroundPicture}
            />
          </div>
          <div className="case-footer">
            <CaseFooter
              locale={locale}
              productTypeShort={fileFrontmatter.productTypeShort}
              segmentTag={fileFrontmatter.segmentTag}
              solutionTag={fileFrontmatter.solutionTag}
              path={fileFrontmatter.path}
              title={fileFrontmatter.title}
              previousPath={previousPath}
              currentCasesArray={currentCasesArray}
              previousPathForPage={previousPathForPage}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query TemplateC($locale: String, $folderName: String) {
    file(name: { eq: $locale }, relativeDirectory: { eq: $folderName }) {
      childMarkdownRemark {
        frontmatter {
          title
          path
          description
          panoBannerImage{
            panoImage
            hlookat
            vlookat
            fov
            limitView
          }
          readingTime
          brochureMessage
          brochure{
            publicURL
          }
          segmentTag
          areaTag
          solutionTag
          tags
          panoCarouselFolder
          panoCarousel360{
            panoImage
            hlookat
            vlookat
            fov
          }
          productTypeShort
          productType
          client
          clientURL
          caseContentMainHighlight
          caseContentIntro
          caseContentOpportunity
          caseContentOpportunityHeader
          messageFor360Gallery
          externalLink360Gallery
          caseContentSolutionHeader
          caseContentSolution
          caseContentWorkProcessHeader
          caseContentWorkProcess
          caseContentAdvantagesHeader
          public
          banner3D{
            folderName
            specsSmallScreen{
              fov
              minDistance
              enableWireframe
              initialPosition{
                x
                y
                z
              }
              initialTarget{
                x
                y
                z
              }
              polarAngleRange
            }
            specsDesktop{
              fov
              minDistance
              enableWireframe
              initialPosition{
                x
                y
                z
              }
              initialTarget{
                x
                y
                z
              }
              polarAngleRange
            }
          }
          caseContentAdvantages {
            text
            logo
          }
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          highlightImage {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1170, maxHeight: 700) {
                ...GatsbyImageSharpFluid
              }
            }
          }
  
          caseContentAdvantagesBackGroundPicture {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1440, maxHeight: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

/*

  <div className="case-content-work-process">
          <LongTextBlock
            header={fileFrontmatter.caseContentWorkProcessHeader}
            text={fileFrontmatter.caseContentWorkProcess}
            textColor={'#333333'}
          />
        </div>


*/
